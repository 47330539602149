import React, { useRef, useState } from 'react'
import styled, { css, Box } from '@xstyled/styled-components'
import Page from '@/components/Page'
import PageWrapper from '@/components/PageWrapper'
import { Text } from '@/components/Text'
import { H } from '@/components/Heading'
import { P } from '@/components/Paragraph'
import WithSlideShowCheck from '@/components/Helpers/WithSlideShowCheck'
import { pageFadeInOut } from '@/modules/animationVariants'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { SwitchTransition, Transition } from 'react-transition-group'
import { useContentInProp } from '@/hooks/useContentInProp'
import { SplitText } from 'gsap/dist/SplitText'
import gsap from 'gsap'
import { breakpoints } from '@xstyled/system'
import proportion from '@/modules/utils/proportion'
import { TextNavigationLink } from '@/components/TextNavigationLink'
import Links from '@/components/Links'

const Title = styled(H)`
  overflow: hidden;
`

const TabItem = styled(P)<{ active: boolean }>`
  opacity: ${(props) => (props.active ? 1 : 0.4)};
`

const CustomBreakPoint = styled(Box)`
  ${breakpoints({
    /* eslint-disable sort-keys */
    md: css`
      min-height: 620px;
    `,
    /* eslint-enable sort-keys */
  })}
`

const getTabData = (t) => [
  {
    tab: t('dna.join_toggle1', { defaultValue: 'WE EMBRACE EXTRAORDINARITY' }),
    title: t('dna.join_toggle1_title', {
      defaultValue: 'WE EMBRACE EXTRAORDINARITY',
    }),
    url: '/our-dna/values/extraordinarity',
    body: t('dna.join_toggle1_body', {
      defaultValue:
        'We are creative problem-solvers. We are curious and we use our talent to experiment and to test new things, to go at it with ambition and fierceness. We have the courage to bring up unconventional thoughts. We truly understand that creativity is the path to finding new possibilities, to innovation. Together, we discover new ways to do banking, to bring new solutions to the market. We change the industry.',
    }),
  },
  {
    tab: t('dna.join_toggle2', { defaultValue: 'WE ARE RELIABLE' }),
    title: t('dna.join_toggle2_title', {
      defaultValue: 'WE ARE RELIABLE',
    }),
    url: '/our-dna/values/reliable',
    body: t('dna.join_toggle2_body', {
      defaultValue:
        'Our business is about reliability. We understand that. We understand that our clients entrust the continuation of their business to us. We feel that responsibility. That’s why we are dedicated and work hard to deliver. To make things right.',
    }),
  },
  {
    tab: t('dna.join_toggle3', { defaultValue: 'WE VALUE COMPANIONSHIP' }),
    title: t('dna.join_toggle3_title', {
      defaultValue: 'WE VALUE COMPANIONSHIP',
    }),
    url: '/our-dna/values/companionship',
    body: t('dna.join_toggle3_body', {
      defaultValue:
        'We stick together. If somebody from us needs us, we will be there for them. This can be a small request for help, it can be an important migration weekend, it can be a huge bug fixing challenge. It can even be a personal situation. At such a moment, we say: ’I will be there for you. I have your back. So we work together, struggle together and party together. We go above and beyond for each other.',
    }),
  },
  {
    tab: t('dna.join_toggle4', { defaultValue: 'WE TAKE PERSONAL INITIATIVE' }),
    title: t('dna.join_toggle4_title', {
      defaultValue: 'WE TAKE PERSONAL INITIATIVE',
    }),
    url: '/our-dna/values/initiative',
    body: t('dna.join_toggle4_body', {
      defaultValue:
        'At Ohpen you get and take the space to start things. We like it when people take initiative. People that come and work at Ohpen, are that way. We do not take you by the hand. You own your job and you can and will do what it takes to get to the best result. We trust you to take that jump and just do it.',
    }),
  },
  {
    tab: t('dna.join_toggle5', { defaultValue: 'WE PERSEVERE' }),
    title: t('dna.join_toggle5_title', {
      defaultValue: 'WE PERSEVERE',
    }),
    url: '/our-dna/values/persevere',
    body: t('dna.join_toggle5_body', {
      defaultValue:
        'We do things that have not been done before, we find solutions for complex situations, we do things that are difficult to do. Sometimes people even think we do things that are impossible. But we do them. We think hard, we push the boundaries and we deliver.',
    }),
  },
]

const TabView = styled(Box)`
  position: relative;

  ${breakpoints({
    md: css`
      padding-bottom: ${proportion(32, 'desktop')};
    `,
    xl: css`
      padding-bottom: 32px;
    `,
  })}
`

export const Divider = styled.div`
  display: block;
  transform: scaleX(0);
  transform-origin: left;
  position: absolute;
  bottom: 0;
  height: 1px;
  width: 48px;
  background-color: secondary;
`

const ActiveTab = ({ active, title, body }) => {
  const inProp = useContentInProp()
  const bodyRef = useRef(null)
  const titleRef = useRef(null)
  const dividerRef = useRef(null)

  function onExit(el) {
    gsap.fromTo(
      el,
      0.8,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        ease: 'sine.inOut',
      },
    )
  }

  function onEnter() {
    const tl = gsap.timeline({})
    const bodySt = new SplitText(bodyRef.current, {
      type: 'words',
    })
    const titleSt = new SplitText(titleRef.current, {
      charsClass: 'char',
      type: 'chars',
    })

    tl.fromTo(
      titleSt.chars,
      0.8,
      {
        y: 40,
      },
      {
        force3D: true,
        ease: 'sine.out',
        stagger: 0.02,
        y: 0,
      },
      0.4,
    )

    tl.fromTo(
      bodySt.words,
      0.8,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.014,
        ease: 'sine.inOut',
      },
      0.4,
    )

    tl.fromTo(
      dividerRef.current,
      0.5,
      {
        scaleX: 0,
      },
      {
        scaleX: 1,
        ease: 'sine.out',
      },
      0.8,
    )
  }

  return (
    inProp && (
      <SwitchTransition mode="out-in">
        <Transition
          key={active}
          appear
          onEnter={onEnter}
          onExit={onExit}
          timeout={1300}
        >
          <TabView>
            <Title variant="small" ref={titleRef}>
              {title}
            </Title>
            <P variant="bodyLarge" ref={bodyRef}>
              {body}
            </P>
            <Divider ref={dividerRef} />
          </TabView>
        </Transition>
      </SwitchTransition>
    )
  )
}

const SocialPage: React.FC = () => {
  const inProp = useContentInProp()
  const { t } = useTranslation()

  const texts = {
    left: {
      link: t('dna.values_cta', { defaultValue: 'Our People' }),
      pretitle: t('dna.values_super', {
        defaultValue: 'How we cooperate and what matters to us',
      }),
      text: t('dna.values_body', {
        defaultValue:
          'The values of Ohpen define the cultural pillars of the organization. They are not just words or concepts. Instead, they are reflections of the experiences we value and a reflection of the way we want to work together. They evolve with every new colleague that joins Ohpen.',
      }),
      title: t('dna.values_title', { defaultValue: 'Our Values' }),
      url: '/our-dna/ohpeneers',
    },
  }

  const tabData = getTabData(t)
  const containerRef = useRef(null)
  const [activeTab, setActiveTab] = useState(0)
  const { title, body } = tabData[activeTab]

  function onClickTab(index: number) {
    return () => setActiveTab(index)
  }

  function onEnter(el) {
    gsap.fromTo(
      el.children,
      1,
      {
        opacity: 0,
      },
      {
        ease: 'sine.inOut',
        opacity: 1,
        delay: 1.1,
        stagger: {
          each: 0.1,
        },
      },
    )
  }

  const left = (
    <Text {...texts.left}>
      <Transition in={inProp} onEnter={onEnter} timeout={1000}>
        <Box ref={containerRef}>
          {tabData.map((item, index) => (
            <Box key={item.tab}>
              <TabItem
                variant="link"
                active={index === activeTab}
                onClick={onClickTab(index)}
                data-url
              >
                {item.tab}
              </TabItem>
            </Box>
          ))}
        </Box>
      </Transition>
      <TextNavigationLink
        variant="dark"
        delay={1.2}
        time={1}
        url={texts.left.url}
        text={texts.left.link}
      />
    </Text>
  )

  return (
    <>
      <Page
        content={
          <PageWrapper>
            <WithSlideShowCheck
              mobileContent={[
                <>
                  <Text isMobile {...texts.left}>
                    <Links
                      text={texts.left.link}
                      url={texts.left.url}
                      links={tabData}
                    />
                  </Text>
                </>,
              ]}
            >
              <Box row>
                <Box col={0.5}>
                  <Box col={0.8}>{left}</Box>
                </Box>
                <CustomBreakPoint
                  col={0.5}
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Box col={0.8}>
                    <ActiveTab active={activeTab} title={title} body={body} />
                  </Box>
                </CustomBreakPoint>
              </Box>
            </WithSlideShowCheck>
          </PageWrapper>
        }
        settings={{
          backgroundColor: '#113f4f',
          contentAnimationVariants: pageFadeInOut(),
          useCircularWipe: false,
        }}
      />
    </>
  )
}

export default SocialPage
